.results-wrapper {
  border-left: 1px solid rgb(226, 225, 225);
  border-right: 1px solid rgb(226, 225, 225);
  border-bottom: 3px solid rgb(226, 225, 225);
  padding: 5px 10px 5px;

  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;

  div {
    margin-bottom: 5px;
  }
  .results-display-wrapper {
    margin: auto;
  }
}
