.current-rules-wrapper {
  padding-top: 20px;
  .header-bar {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .pdf-create-button {
    background-color: #669b41;
    color: white;
    border-color: #669b41;
  }
}

.header-bar {
  padding-top: 2em;
  font-weight: bold;
}
