.segment-form {
  margin: 10px;
  padding: 10px;
  overflow: hidden;
  width: 80vw;
  max-width: 500px;
  border-radius: 6px;
  //   color: #707070;
  .form-field {
    margin: 10px;
  }
  .form-controls {
    display: flex;
    justify-content: flex-end;
  }
  .form-controls > button {
    margin: 10px;
  }
  .save-btn {
    background-color: #003cbd;
  }
  .form-title {
    font-size: 14pt;
    margin-bottom: 10px;
  }
}
