.filing-home-tab {
  .MuiPaper-root {
    position: relative;
    max-width: 97%;
    margin: auto;
    z-index: 1300;
  }
  // button {
  //     color: white;
  //     background-color: #003cbd;
  // }

  .tab-container {
    display: flex;
    justify-content: space-between;
  }

  .tabs button {
    color: white;
    background-color: #003cbd;
  }
}
