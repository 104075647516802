.filing-home {
  .MuiDialog-container > .MuiPaper-root {
    z-index: 1300;
  }

  .diff-hide {
    display: none;
  }

  .diff-visible {
    padding: 0 10px 0 10px;
    margin: 0 0 0 10px;
    width: 100%;
    background-color: #f5f5f5;
  }

  .save-text {
    float: right;
  }

  div::-webkit-scrollbar {
    width: 10px;
  }

  div::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
  }

  div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
  }
}

