.notification-form {
  .notification-name {
    margin-bottom: 40px;
    width: 100%;
  }
  .chip-list-container {
    display: flex;
    overflow-wrap: normal;
    margin-bottom: 6px;
    flex-wrap: wrap;
    .string-chip {
      margin: 4px;
    }
  }
  .editor {
    margin-top: 45px;
    border: 1px solid black;
  }
  .notification-editor {
    margin-top: 5px;
    p {
      margin: 0px;
    }
  }
  .editor {
    height: 300px;
    width: 100%;
    overflow: auto;
    .editor::-webkit-scrollbar {
      width: 10px;
    }

    .editor::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
    }

    .editor::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
    }
  }
}
