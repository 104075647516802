fieldset.fieldset {
  border-radius: 5px;
  padding: 2em;
  background-color: #ffffff;
  margin-bottom: 1.5em;
  border: 2px solid threedface;
  border-style: groove;
  min-width: 80%;
}

legend.checkbox-legend {
  font-size: 20px;
  padding: 0 5px;
  color: #000000de;
  //   margin-bottom: 0.8em;
  //   display: none;
}
