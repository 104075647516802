.edit-form-wrapper {
    width: 100vw;
    margin-left: 5px;
    box-shadow: 0px 10px 20px #00000029;
    // border-radius: 30px;
    .Chapter {
        background-color:#789A50 !important;
        color:white;
        border-radius: 5px;

    }

    .Section {
        background-color:#48A4E1 !important;
        color:white;
        border-radius: 5px;
    }

    .Part {
        background-color:#48A4E1 !important;
        color:white;
        border-radius: 5px;
    }

    .Subchapter {
        background-color:#CE6F33 !important;
        color:white;
        border-radius: 5px;
        margin-left: 0;

        display: inline-flex;
        width: 100%;

    }
}

.edit-card {
    min-height: auto;
    .card-body {
        overflow-y:auto;
    }
}

.edit-form-wrapper > div {
    min-height: 100% !important;
}

.test {
    background-color: red !important;
}

.edit-form-header {
    padding: 40px;
    text-align: center;
    background: #f9f7f7;
    margin:30px;
    font-size:20px;
}

.page { padding: 20px 10px; }
.content {
    background: #F9F9F9;
    height: 470px;
    overflow: auto;
}

.edit-form {
    margin-top: 5%;

}

.edit-form-source {
    margin-left: 5px;
    width: 300px;
}

.select-boxes {
    text-overflow: ellipsis;
    width:20rem;
}

.status-list {
    margin-left: 5px;
    display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: right;
}

.editor-compare {
    overflow-y: auto;
    font-family: 'Times New Roman', Times, serif;
    margin-top: 0.5rem;
    height: 100%;
    ins {
        background-color: rgba(0, 255, 0, 0.3);
      }
    del {
    background-color: rgba(252, 0, 0, 0.3);
    }
    display:flex;
    flex-direction: column;
    padding:.5rem;
    .diff-close {
        margin:.5rem;
        float: right;
        padding:0px !important;
    }
}