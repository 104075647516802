.draft-preview {
  > div > div {
    min-width: 75%;
  }
  .section-label {
    display: inline-block;
  }
  .loading-spinner {
    display: flex;
    justify-content: center;
    > * {
      margin-right: 0.5rem;
    }
  }

  // this is being used to override any default styles on appendix images
  // they need to occupy all the space available so they aren't too small when downloading pdfs
  img {
    width: 100% !important;
  }
}

.emergency-dates {
  font-size: large;
}

.emergency-effective-date {
  // padding-right: 5px !important;
}
