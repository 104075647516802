.quicklinks {
  margin: 170px 0em 0em 0em;

  p.MuiTypography-root.code-search-title.MuiTypography-body1 {
    text-align: right;
    margin-top: 30px;
    margin-bottom: none;
    font-size: xx-large;
    color: #464646;
  }

  .underline {
    width: 17rem;
    background-color: #464646;
    border: 1px solid #464646;
    border-radius: 5px;
    padding: 0.3px;
  }
  .MuiAccordionDetails-root {
    padding-top: 1em !important;
  }

  .links {
    width: 100%;
  }

  .links a {
    display: flex;
    text-decoration: none;
    color: #3142a0;
  }

  .links a {
    margin-top: 5px;
  }

  .links > p {
    margin-top: 5px;
  }

  .list-item {
    margin-top: 0px !important;
    margin-bottom: 0px;
  }

  .list-item > div > div {
    display: flex;
    align-items: center;
  }

  .links a:hover {
    font-weight: bold;
  }

  .links > div > div {
    display: flex;
    align-items: center;
  }

  .quicklinks-title {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 0;
    font-size: xx-large;
    color: #464646;
    text-transform: uppercase;
  }

  .label {
    background-color: #0066A6 !important;
    border-radius: 3px !important;
    margin-top: 2px !important;
  }

  .label:focus {
    border-radius: 3px !important;
    background-color: #00216d !important;
  }

  .label > div {
    color: white;
  }

  .form-fields div {
    margin: 5px;
  }

  .agency {
    width: 100%;
  }

  .agency > div {
    margin: 10px;
  }

  .agency-paper {
    border-left: 10px solid #de9027 !important;
    display: flex;
  }

  .agency-paper > div {
    flex-direction: row;
    padding: 10px;
    width: 100%;
    flex-direction: column;
  }

  .header {
    // background-color: #de8f27;
    margin-bottom:5px;
    // color: white;
    // padding: 5px;
    border-radius: 3px;
  }

  .contact-wrapper {
    overflow-x:auto;
    font-size: .7rem;
  }

  .title {
    overflow-x:auto;
    font-size: .8rem;
  }

  .rule {
    overflow-x:auto;
    font-size: .8rem;
  }

  .agency-label {
    font-weight: bold !important;
  }

}
