.form-container {
  display: inline-block; /* Use a flexbox layout */
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
  text-align: center;
}

.form-element {
  margin-top: 5rem;
  text-align: center;
  //padding-left:  10%;
  //padding-right: 10%;
  //width: 100%;
}