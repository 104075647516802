.action-expansion {
  margin: 10px 0px 10px 0px;
}

.form-icon-select {
  display: flex;
  padding-top: 14px;
}

.panel-summary {
  border-bottom: 1px solid rgb(1, 46, 92) !important;
}

.panel-summary > div {
  color: rgb(236, 236, 236);
  background-color: #004E9A;
  font-size: 14pt;
}

.dropdown {
  // margin: 15px;
  margin: 2px;
  max-width: 99.8%;
}

.new-panel-summary {
  border-bottom: 1px solid rgb(1, 46, 92) !important;
  color: rgb(236, 236, 236) !important;
  background-color: #D9B310 !important;
  font-size: 14pt;
}

.form-icon-text {
  padding-right: 10px;
  align-self: center;
  color: #6E6E6E !important;
}

.text-field {
  padding: 2px !important;
  margin-top: 10px !important;
}

.action-form {
  justify-content: center;
  width: 100%;
}

.enum-select-input {
  background-color: rgba(0, 0, 0, 0.10);
  margin: 2px;
  margin-top: 10px;
  border-radius: 4px 4px 0px 0px;
  padding: 8px 8px 1px 8px;
}

.panel-summary > div{
  justify-content: space-between;
}

.status-alert {
  margin: 5px 0px 5px 5px;
  color: #ee0000;
}

.select-label {
  font-size: 12px !important;
  margin-left: 3px;
}