.tab-menu {
  .body {
  }

  .tabs {
    display: flex;
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    justify-items: space-between;
  }
  .tab {
    flex: 1;
  }
}
