.notice-filing-tab
{
    .button-container{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .action-icon button{
        height: 40px !important;
        width: 40px !important;
    }

}