.status-edit-wrapper {
  .status-settings-container {
    float: left;
    transition: color ease-out 250ms;
    font-size: 18pt;
    text-align: end;
  }
  > .status-settings-container:hover + .status .MuiCardContent-root *{
    color: #932020;
    cursor: pointer;
  }
  > .status-settings-container:hover {
    color: red;
    cursor: pointer;
  }
}
