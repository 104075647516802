.split {
    display: inline-flex;
    height: calc(100vh - 20rem);
    width: 100%;
}

.split > div {
    overflow-y:auto;
    float: left;
    height: 100%;
    width: 100%;
}

.gutter {
    background-color: rgb(243, 243, 243);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    padding: .6rem;
}

.gutter.gutter-horizontal {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
    cursor: col-resize;
}

.draft-toolbar {

    .draft-button {
        text-transform: none !important;
    }
}