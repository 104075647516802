.reports-page {
  margin-top: 1rem;
}
.report-form {
  display: flex;
  flex-direction: column;
  > * {
    margin-top: 0.5rem;
    width: 50svw;
  }
  .input-description {
    font-weight: bold;
  }
  .report-button-group {
    > * {
      margin-right: 0.5rem;
      margin-top: 0.5rem;
    }
  }

  .loading-report-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    > span {
      font-weight: bold;
      font-size: 16pt;
      margin-right: 0.5rem;
    }
  }
}
