.work-queue {
    .button-group {
        float: right;
    }

    .k-grid-header .k-header {
        height: 20px;
        padding: 10px;
    }

    .k-filter-row {
        height: 15px;

        th {
            padding: 5px !important;
        }
    }

    .newIcon {
        color: #3f51b5;
    }

    .oldIcon {
        color: rgb(177, 1, 1);
    }
}