.reg-result {
    // padding: 16px 3em .2em 3em;
    max-width: 100%;
    border: 1px solid #eee;
    box-shadow: 0 2px 3px #ccc;
    margin: 10px;
    margin-bottom: 3px;
    box-sizing: border-box;
    cursor: pointer;
}

.reg-result:hover,
.reg-result:active {
    background-color: #C0DDF5;
}

.title-style {
    vertical-align: middle;
}

.result-details {
    display:flex;
}

.results-header {
    text-align: center;
    justify-items: start;
    padding-right: 10px;
    margin: 0.5em;
    display: inline-flex;
    margin: 0 1rem;
    max-width: 100%;

}

.text-contents {
    width: 70vw;
}

.card-img-top {
    width: auto;
    min-width: 6em;
    max-width: 10em;
    height: auto;
    max-height: 12em;
    vertical-align: middle;
}

.result-header-item {
    font-size: medium;
    color: #3f51b5;
    // justify-content: space-between;
    svg {
        color: #3f51b5;
        height: 1.3rem;
        margin-left: 1rem;
        align-content: center;
        margin-bottom: 0px;
    }
}

.result-header-value {
    font-style: italic;
    color: gray;
}

.header-values {
    display: inline-flex;
    margin: 0 1rem;
    max-width: 100%;
}

.center {
    text-align: center;
}

.name-spacing {
    width: 10%;
    text-align: left;
}

.title-spacing {
    width:70%;
    text-align: left;
}

.code-spacing {
    width: 20%;
    text-align: left;
}


