.edit-tab {
  // display: flex;
  .text-edit-position {
    position: absolute;
    float: right;
  }

  .diff-hide {
    display: none;
  }

  .diff-visible {
    padding: 0 10px 0 10px;
    margin: 0 0 0 10px;
    width: 100%;
    background-color: #f5f5f5;
  }

  ins {
    background-color: rgba(0, 255, 0, 0.3);
  }

  del {
    background-color: rgba(252, 0, 0, 0.3);
  }
  .save-button {
    float: right;
    margin: 10px;
  }
  .save-button > button {
    background-color: #003cbd;
  }
}
