.add-register-item-modal {
    > div > div  {
        min-width: 75%;
    }
    .section-label {
        display:inline-block;
    }
}

.preview-frame {
    width: 100%;
    height: 80vh;
}

@media print {
    .preview-frame {
        background-color: white;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        padding: 15px;
        font-size: 14px;
        line-height: 18px;
    }
}

.register-preview-actions {
    display: flex;
    flex-direction: row;
    font-size: large;
    margin-bottom: .5em;
    justify-content: space-between;
    align-content: center;
}

.register-info-text {
    display:flex;
    max-width: 50%;
}
