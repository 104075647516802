.reports-wrapper
{
    .reports-tab {
        background-color: white !important;
        // color: green !important;
    }    
    .tab-menu:hover {
        background-color: #dddddd !important;
    }
    // .MuiTabs-indicator {
    //     color: blue !important;
    //     width: 100px !important;
    //     height: 2px !important;
    // }
    .MuiTab-root {        
          padding: 0.9rem !important;
          font-size: 0.7rem !important;
    }        
}
