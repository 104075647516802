.segment-hierarchy {
  overflow-y: auto;
  justify-content: center;
  .add-segment-button {
    float: right;
    margin-bottom: 10px;
    color: white;
    background-color: #004e9a !important;
    margin-top: 5px !important;
  }
}
