.news {
  padding: 170px 3em 0em 3em;

  p.MuiTypography-root.code-search-title.MuiTypography-body1 {
    text-align: right;
    margin-top: 30px;
    margin-bottom: none;
    font-size: xx-large;
    color: #464646;
  }

  .underline {
    width: 17em;
    background-color: #464646;
    border: 1.5px solid #464646;
    border-radius: 5px;
    padding: 0;
  }

  .news-blog {
    margin: 1% 6%;
    .news-post {
      margin-top: 3rem;
    }
  }
  .pagination {
    display: flex;
    margin-top: 20px;
    margin-bottom: 50px;
    align-content: center;
    justify-content: center;
  }

  .news-title {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 0;
    font-size: xx-large;
    color: #464646;
    text-transform: uppercase;
  }
}

// .MuiButtonBase-root {
//   margin: -5px !important;
//   padding: 5px !important;
// }
