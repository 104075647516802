.search {
  .search-bar {
    display: "flex";
    // background-color: white;
    .MuiFormControl-root > div {
      background-color: white;
    }
    .search-input {
      flex: 1;
      border-radius: 5px;
      background-color: rgb(255, 255, 255) !important;

      .search-input > div {
        // border-color: black;
        border-style: solid;
        border-radius: 4px;
        border-width: 2px;
        border-color: white;
      }
    }
  }

  .search-button {
    // font: inherit;
    font-style: bold;
    font-size: 10pt;
    font-weight: bold;
    background-color: rgb(255, 255, 255) !important;
    color: black;
    width: 19rem;
    margin-top: 3px;
    // background-color: rgb(255, 255, 255) !important;
    // color: rgb(63, 63, 63) !important;
    //.MuiFormControl-root:focus {
    //  border: 1px solid #1ba6df;
    //}
  }
  .search-button:hover {
    background-color: #1ba6df;
    color: 1ba6df;
  }
  .search-tools {
    display: flex;
    justify-content: space-evenly !important;
    .search-button {
      padding: 5px;
      margin-top: 16px;
    }
  }
}
