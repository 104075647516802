#editor {
  .underline {
    text-decoration: underline;
    text-decoration-color: black;
  }
}


@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Montserrat"), local("Montserrat-Regular"),
    url(../../fonts/Montserrat/Montserrat-Regular.ttf) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
