.owning-entity.owning-entity {
  margin-bottom: 10px;
  .MuiAccordionDetails-root {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    margin-top: -60px;
  }
}
.owning-body {
  padding: 5px;
  border: 1px solid rgb(160, 159, 159);
  width: 120%;
}
.owning-entity-drawer {
  [aria-expanded="false"]:hover {
    border-left: 8px solid #117bc0;
    margin-left: -8px;
    border-radius: 5px;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  [aria-expanded="true"] {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-bottom: auto;
    // color: white;
  }

  p.MuiTypography-root.agency-title.MuiTypography-body1 {
    font-size: 1.5em;
  }
}

// .segment-display.level-0:hover {
//   border-left: 8px solid #117bc0;
//   margin-left: -8px;
//   border-top-left-radius: 5px;
//   border-bottom-left-radius: 5px;
// }
