.filing-sub-tab {
  // margin-top: 16px;
  button {
    background-color: #1ca6df;
    color:black;
  }
  button:hover {
    background-color: #6fcaf1;
  }
}

.filing-sub-tab {
  background-color: white !important;
  .Mui-selected {
    background-color: #187dc3;
    color:white;
  }
  .Mui-selected:hover {
    background-color: #125b8f;
    color:white;
  }
  .MuiTab-root {
    min-width: 80px !important;
    padding: 0.2rem !important;
    font-size: 0.7rem !important;
  }
  padding: 0px !important;
}
