.generic-table {
    .k-button {
        border: 1px solid black;
        border-radius: 4px !important;
        margin-left: 2px;
        background-color: white;
        color: black;
        padding: 6px 20px;
        font-size: 12px;
        cursor: pointer;
    }
}
