.sub-filing-tab {
  .action-icon button {
    height: 40px !important;
    width: 40px !important;
  }
  .sub-filing-task-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: black 1px solid;
  }
  .add-sub-filing-button{
      float: right;
  }
  .sub-filing-container {
    
  }
}
