.dialog {
    .email-text-field {
        margin-bottom: .75rem !important;
    }
}
.form-group {
    display:grid !important;
    grid-template-columns: repeat(3, 33% [col-start]);
}
.form-label {
    background: #004e9a;
    color:white;
    padding-left:.3rem;
    border-radius: 4px;
}

.select-label {
    font-weight:bolder !important;
    font-size: .875rem !important;
    margin-left:0px;
}

.multi-select {
    margin-bottom: .75rem !important;
}