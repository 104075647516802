.draft-editor {
  .segment {
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .exec-order-year {
    color: black;
    // border-radius: 5px 5px 5px 5px;
    border-left: 0.5rem solid #789a50 !important;
    > div {
      margin: 0px;
    }
  }

  .Chapter {
    // background-color:white !important;
    color: black;
    // border-radius: 5px 5px 5px 5px;
    border-left: 0.5rem solid #789a50 !important;
    > div {
      margin: 0px;
    }
  }

  .Section {
    // background-color:white !important;
    color: black;
    // border-radius: 5px 5px 5px 5px;
    border-left: 0.5rem solid #004e9a !important;
    > div {
      margin: 0px;
    }
  }

  .Part {
    // background-color:white !important;
    color: black;
    // border-radius: 5px 5px 5px 5px;
    border-left: 0.5rem solid #48a4e1 !important;
    > div {
      margin: 0px;
    }
  }

  .Subchapter {
    // background-color:white !important;
    color: black;
    // border-radius: 5px 5px 5px 5px;
    border-left: 0.5rem solid #d15420 !important;
    > div {
      margin: 0px;
    }
  }

  .Appendix {
    // background-color:white !important;
    color: black;
    // border-radius: 5px 5px 5px 5px;
    border-left: 0.5rem solid #a96728 !important;
    > div {
      margin: 0px;
    }
  }

  .Executive {
    color: black;
    // border-radius: 5px 5px 5px 5px;
    border-left: 0.5rem solid #004e9a !important;
    > div {
      margin: 0px;
    }
  }

  .edited-item {
    background-color: rgb(212, 255, 212) !important;
  }

  .draft-details {
    justify-content: end !important;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    border: 1px solid rgb(170, 170, 170);
    // border-radius: 0px 0px 5px 5px;
    > div {
      width: 100% !important;
    }
  }

  .segment-card {
    margin: 0.5rem;
  }

  .segment-card-header-hierarchy {
    display: flex;
    justify-content: flex-end;
    // background-color: rgb(228, 228, 228);
  }

  .draft-info {
    max-width: 90% !important;
  }

  .split-button {
    > button {
      font-size: 0.8rem;
      padding-top: 0px;
      padding-bottom: 0px;
      background-color: white;
      color: black;
      cursor: pointer;
      text-transform: none;
      box-shadow: 2px 4px 4px 1px rgba(0, 0, 0, 0.4);
    }
  }

  .cancel-button {
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: white;
    color: red;
    margin-left: 5px;
    cursor: pointer;
    text-transform: none;
    box-shadow: 2px 4px 4px 1px rgba(0, 0, 0, 0.4);
  }
  .diff-description {
    > * {
      margin-right: 3px;
    }
  }
}
// .note-zone {
//     display:inline;
// }

// .note-text {
//     display:inline;
// }

.note-zone:hover {
  cursor: pointer;
  border-radius: 2px;
  border-bottom: 2px solid grey;
}

.inline-note-text {
  // display: inline-block !important;
  font-size: 11pt !important;
  // float: left;
}
.inline-note-text > * {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  display: inline;
}
.note > * {
  // display: inline-block;
  font-size: 11pt !important;
}
