.status-modal {
  display: flex;
}

.dialog-title {
  padding: 100px;
}

.form-icon-select {
  display: flex;
  padding-top: 14px;
}

.form-icon-text {
  font-size: 14pt;
  color: rgb(125,125,125);
  padding-right: 10px;
  align-self: center;
}

.status-select > div{
  width: 100%;
}

.workflow-add {
  display: flex;
  align-content: center;
  justify-content: center;
}

.add-new-icon {

  margin-top: 10px !important;
  background-color: #004E9A !important;
  color: white !important;
}