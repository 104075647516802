.subscriptions-heading {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  //margin-top: 5rem;
}

.agency-dropdown {
  display: inline-flex;
  //width: ;
  //margin-bottom: 20px;
}

.chapters-results {
  padding-top: 5em;
  margin-bottom: 10em;
}

.search-rules {
  width: 70vw;
}

.rule-management {
  height: 100%;
  // background: url(https://sos.nebraska.gov/sites/all/themes/sos/img/bg-ne-seal.png) no-repeat fixed
  // center;
  background-size: cover;
  margin-top: 1rem;
  //max-width: 90vw;
  min-height: 300px;
  font-family: Arial, Helvetica, sans-serif;
  //margin-left: 0.5rem;
  .main-label {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 10px;
  }
}
//.rule-management {
//  margin-left: 5vw;
//  margin-right: 5vw;
//}
.rule-management > * {
  // this was causing the background image to show through just below the tabs
  // margin-bottom: 1rem;
}

.card-container {
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12) !important;

  //min-height: 200px;
  //margin: 100px auto;
  background: white;
  border-radius: 5px;
  margin-right: 1rem;
}

.search-rules {
  padding-right: 10px;
  margin-left: 5vw;
  margin-right: 5vw;
  margin-top: 2em;
  min-height: 400px;
  width: 100%;
}

.account-details {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem;
  width: auto;
  .subscription-details {
    padding-top: 0.5rem;
    min-width: 80svw;
    flex: 3;
  }
  .subscription-profile {
    padding-top: 0.5rem;
    flex: 1;
  }
  overflow-wrap: normal;
}

.subscription-profile-header {
  width: 100vmax;
  text-align: center;
}

.edit-profile-button {
  display: flex;
  justify-content: center;
}

.code-link {
  margin: 0 5px 0 5px !important;
}
