.button-group {
    float:right;
    .k-button {
        border: 1px solid black;
        border-radius: 4px !important;
        margin-left: 2px;
        background-color: white !important;
        color: black;
        padding: 6px 20px;
        font-size: 12px;
        cursor: pointer;
    }
}

.add-button-group {
    float:left;
    width: auto;
    margin:10px;
    .k-button {
        border: 1px solid black;
        border-radius: 4px !important;
        margin-left: 2px;
        color: black;
        padding: 6px 20px;
        font-size: 12px;
        cursor: pointer;
    }
}

.empty-table-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.center-text-heading {
    font-size: 24px;
    display: flex;
    justify-content: center;
}

.warn-button {
    margin-left: .5em;
    background-color: #ff6358;
    color: white;
}

.k-grid-header .k-header {
    height: 20px;
    padding: 10px;
  }
.k-filter-row {
    height: 15px;
    th {
        padding: 5px !important;
    }

}