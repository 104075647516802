label.MuiFormLabel-root {
  color: black;
  font-weight: bolder;
  font-size: 1em;
}

.MuiAccordionDetails-root {
  display: flex !important;
  justify-content: center !important;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-3 {
  margin-top: 1.1em;
}

p.MuiTypography-root.makeStyles-heading-265.MuiTypography-body1 {
  font-size: 16px;
}
span.MuiBox-root.MuiBox-root-19 {
  margin-right: auto;
}


.titleList {
  margin-left: 2rem;
}

.datePick {
  margin-left: 2rem;
  // margin-bottom: 5rem;
}

.checkbox-display {
  margin-left: 1rem;
  // margin-bottom: 5rem;
}

.grid-layout-container {
  display:flex;
  align-items: center;
  .facetbox {
    display:flex;
    flex-direction: column;
    justify-items: center;
    .filter-label {
      flex-direction: row !important;
      flex-wrap:wrap;
      .chip {
        justify-content: space-between !important;
        max-width: 15rem;
        overflow: wrap;
      }
      flex-direction: column;
    }
  }
}
