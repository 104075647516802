.action-list {
  margin: 10px 0px 10px 0px;
}

.form-icon-select {
  display: flex;
  padding-top: 14px;
}

.panel-summary {
  background-color: #004E9A !important;
  color: white !important;
  font-size: 14pt;
  border-bottom: 1px solid rgb(1, 46, 92) !important;
}

.form-icon-text {
  padding-right: 10px;
  align-self: center;
}

.text-field {
  padding: 2px !important;
}

.add-action-button {
  background-color: #004E9A !important;
  margin-top: 5px !important;
}