.display-pane-right {
  margin: 5px 0px 0px 2.5px;
  width: 50%;
  padding: 10px;
}


.mywork-links {
  margin: 1em;
}

#links {
  max-height: 20vh;
  overflow-y: auto;
}