.comment-thread {
  margin-top: 15px;
  background-color: #dddddd;
  padding: 3px;
  border-radius: 4px;
  .comment-thread-title {
    font-size: 12pt;
  }
  .comment-list {
    background-color: white;
    padding: 10px;
  }
}
