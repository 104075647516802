.work-queue {

    .button-group {
        float:right;
    }

    .k-button {
        border: 1px solid black;
        border-radius: 4px !important;
        margin-left: 2px;
        background-color: white;
        color: black;
        padding: 6px 20px;
        font-size: 12px;
        cursor: pointer;
    }

    .k-grid-header .k-header {
        height: 20px;
        padding: 10px;
    }

    .k-filter-row {
        height: 15px;
        th {
            padding: 5px !important;
        }
    }

    .newIcon {
        color: #3f51b5;
    }

    .oldIcon {
        color: rgb(177, 1, 1);
    }

    .inactiveIcon {
        color: rgb(255, 170, 0)
    }
    // .k-toolbar {
    //     background-color: rgba(175, 199, 252, 0.507);
    // }
    .work-table {
        // tr {
        //     min-height: auto;
        // }
    }

    .table-buttons {
        font-size: .5rem !important;
        // display:flex;
        flex-direction: column;
        padding:2px;

        * {
            align-content: center !important;
        }

        .preview-button {
            border: 2px solid #004e9a;
            border-radius: 4px;
            // color:#004e9a;
        }

        .open-button {
            border: 2px solid #326820;
            border-radius: 4px;
            // color:#326820;
        }

        button {
            width:100%;
            text-transform: capitalize;
            margin-top: .1rem;
            margin-bottom: .1rem;
            justify-content: start;
        }
    }
}

.k-grid tbody td {
    padding: 5px;
    font-size: 16px;
}