.search-wrapper {
  border: 1px solid darkgrey;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 100%;
}

.MuiPaper-root.MuiAccordion-root.Mui-expanded.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
  background-color: rgb(245, 245, 245);
}

.search-container > .search-wrapper-date-container {
  margin: 0 0 10px 1.5em;
  width: unset;
}

.search-container {
  padding: 20px;
}

.suggestion-style {
  width: 25rem;
  margin-left: 1rem;
}

div.container a.input-group-btn {
  font-size: 14px;
}

.suggestions {
  position: relative;
  display: inline-block;
  width: inherit;
  z-index: 99;
  padding: 20px;
}

.input-group {
  flex-wrap: nowrap;
}

// Advanced Search Styles
label.MuiFormLabel-root {
  color: black;
  font-weight: bolder;
  font-size: 1em;
}

.MuiAccordionDetails-root {
  display: flex;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-3 {
  // margin-top: 1.1em;
}

p.MuiTypography-root.makeStyles-heading-265.MuiTypography-body1 {
  font-size: 16px;
}
span.MuiBox-root.MuiBox-root-19 {
  margin-right: auto;
}

.MuiAccordionDetails-root {
  // padding-top: 4em !important;
}

.titleList {
  margin-left: 2rem;
}

.datePick {
  margin-left: 2rem;
  // margin-bottom: 5rem;
}

.checkbox-display {
  margin-left: 1rem;
  margin-bottom: 2rem;
}
.caption-display {
  font-size: 18;
  font-weight: bold;
  margin-left: 1rem;
  margin-bottom: 5rem;
}

.grid-layout-container {
  align-items: center;
  overflow-x: auto;
}

.proposed-check {
  margin-left: 1rem;
  margin-top: 0.5rem;
  margin-bottom: -3rem;
}

.emergency-check {
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #800000 !important;
  }
}
