.admin-edit-footer {
  display: flex;
}

.status-list {
  position: relative;
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: right;
}

.select-boxes {
  text-overflow: ellipsis;
  width: 20rem;
}

.k-animation-container {
  z-index: 10000 !important;
}

.k-dialog-wrapper {
  z-index: 10000 !important;
}

.preview-button {
  margin-bottom: 10px !important;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    margin: 1rem;
  }
}

.hidden {
  display: none;
}

.center {
  display: flex;
  justify-content: center;
}

.bold {
  font-weight: bold;
}

.chapter-font {
  font-size: 14pt;
}

.note-text {
  display: inline-block !important;
}

.preview-note-text {
  display: inline !important;
  font-size: 9pt !important;
  // float: left;
}

.edit-preview-note {
  * {
    display: inline-block !important;
  }
  display: block !important;
  font-size: 9pt !important;
}
