.list-display {
    display: inline-block; /* Get all images to show in a row */
}

@media (max-width: 960px) and (min-width: 501px) {
    .list-display { width: 50%; } /* Show 2 logos per row on medium devices (tablets, phones in landscape) */
}

@media (max-width: 500px) {
    .list-display { width: 100%; } /* On small screens, show one logo per row */
}