.code-search {
  background-color: rgb(255, 255, 255);
  padding-bottom: 3em;
  padding-top: 100px;

  // .code-search-title {
  //   font-weight: bolder;
  //   font-size: 1.1em !important;
  // }

  p.MuiTypography-root.code-search-title.MuiTypography-body1 {
    text-align: right;
    margin-top: 30px;
    margin-bottom: none;
    font-size: xx-large;
    color: #464646;
  }

  .underline {
    width: 17em;
    background-color: #464646;
    border: 1px solid #464646;
    border-radius: 5px;
    padding: 0.3px;
  }

  //   .underline2 {
  //     width: 13em;
  //     background-color: #d25421;
  //     border: 1px solid #d25421;
  //     border-radius: 5px;

  //     padding: 7px;
  //   }
  //   .underline3 {
  //     width: 11em;
  //     background-color: #dd8e25;
  //     border: 1px solid #dd8e25;
  //     border-radius: 5px;

  //     padding: 6px;
  //   }
  //   .underline4 {
  //     width: 9em;
  //     background-color: #107bbf;
  //     border: 1px solid #107bbf;
  //     border-radius: 5px;

  //     padding: 5px;
  //   }
  //   .underline5 {
  //     width: 7em;
  //     background-color: #1aa5de;
  //     border: 1px solid #1aa5de;
  //     border-radius: 5px;

  //     padding: 4px;
  //   }
  // .code-search-title {
  //   text-align: center;
  //   margin-top: 30px;
  //   margin-bottom: none;
  //   font-size: xx-large;
  //   color: rgb(0, 0, 0);
  // }

  //   .underline6 {
  //     width: 5em;
  //     background-color: #669d42;
  //     border: 1px solid #669d42;
  //     border-radius: 5px;
  //     padding: 3px;
  //   }
  //   .underline7 {
  //     width: 3em;
  //     background-color: #d25421;
  //     border: 1px solid #d25421;
  //     border-radius: 5px;
  //     padding: 2px;
  //   }
}

.code-search-title {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 0;
  font-size: xx-large;
  color: #464646;
  text-transform: uppercase;
}
.code-search-subtitle {
  text-align: center;
  // margin-top: 30px;
  margin-bottom: 0;
  font-weight: bold;
  font-size: 12pt;
  // font-size: xx-large;
  color: #464646;
  // text-transform: uppercase;
}
.shrunk-h2 {
  font-size: 30px;
  font-weight: 500;
}

.visually-hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  color: transparent;
  opacity: 0.01;
}

.shrunk-h2 {
  font-size: 20px;
  font-weight: 500;
}

.visually-hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  color: transparent;
  opacity: 0.01;
}


.searchResults__result h4 {
  margin-top: 0px;
  text-transform: uppercase;
}

.searchResults__result .resultDescription {
  margin: 0.5em 0 0 0;
}

.main-content {
  margin-top: 200px !important;
}

#searchBox {
  // background-color:blue !important;
  color:white;
}

.search-bar {
  color:blue;
  min-height: 100px !important;
  min-width: 200px;
}

.wrapper {
  width:100% !important;
}

.search-body {
  display:flex;
  margin: 0.5em;
}

.facets > div {
  // display:flex;
  // flex: 1;
  // border: 1px solid darkgrey;
  // border-radius: 4px;
  // max-width: 0%;
}

.facets {
  height: 100%;
}

.results {
  flex: 3
}