.register-wrapper {
  border: 1px solid darkgrey;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 100%;
}

.MuiPaper-root.MuiAccordion-root.Mui-expanded.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
  background-color: rgb(245, 245, 245);
}

.register-container > .search-wrapper-date-container {
  // margin: 0 0 10px 1.5em;
  // width: unset;
}

.register-container {

  padding: 20px;
}


