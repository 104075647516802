.segment-tree {
  display: flex;
  overflow-y: auto;
  width: 100%;
  flex-direction: column;
  // background-color: rgba(58, 58, 58, 0.08);
  // border-radius: 5px;
  // border-left: 2px dotted #afafaf;
  .button-cancel {
    color: red;
  }
  .actions {
    display: flex;
    align-items: center;
    margin: 5px;
    // justify-items: space-between;
    // flex-direction: row-reverse;
  }
  .title > div {
    min-width: 20vw;
  }
  .controls {
    display: flex;
    justify-content: space-betweeen;
  }
  .expand-button button {
    border: solid rgb(56, 56, 56) 1px;
    padding: 5px;
  }
  .save-button {
    margin-right: 10px !important;
    background-color: white;
    // color: white;
  }
  .save-button:hover {
    background-color: #003cbd !important;
    color: white;
    opacity: 0.7;
  }
  .editor-button {
    margin-right: 10px !important;
    background-color: #003cbd;
    color: white;
    // float: right;
  }
  .editor-button:hover {
    background-color: #003cbd !important;
    opacity: 0.7;
  }
  .segment-type {
    // margin-right: 10px;
    width: 150px;
    align-self: flex-end;
    font-weight: bold;
    // color: white;
  }
  // .tree-form {
  //   display: flex;
  // }
  .expansion {
    margin-left: 10px;
    font-size: 12pt;
  }

  .expansion-modified {
    margin-left: 10px;
    font-size: 12pt;
    background-color: lightblue !important;
  }

  .list-item {
    justify-content: space-between;
  }
  .delete-button {
    color: #cc0000;
  }
  .input-box {
    margin: 2px;
  }
  .editor-control {
    display: flex;
    flex-direction: row-reverse;
  }
  .editor-control span {
    font-size: 8pt;
  }

  // ###########################################################
  // This is for styling hierarchy items at a specific depth
  // ###########################################################

  .expansion-chapter {
    border-left: solid 10px #003cbd;
    border-right: 1px solid rgb(190, 190, 190);
    border-bottom: 1px solid rgb(190, 190, 190);
    border-radius: 3px 3px 3px 3px;
    border-top: 1px solid rgb(190, 190, 190);
    margin-bottom: 4px;
  }
  .expansion-section {
    border-left: solid 10px orange;
    border-radius: 3px 2px 2px 3px;
    border-top: 1px solid rgb(190, 190, 190);
  }
  .expansion-appendix {
    background-color: #e4e4e4;
    border-left: solid 10px #b61c31;
    border-radius: 3px 2px 2px 3px;
    border-top: 1px solid rgb(190, 190, 190);
  }
  .expansion-sub {
    background-color: #e4e4e4;
    border-left: solid 10px green;
    border-radius: 3px 2px 2px 3px;
    border-top: 1px solid rgb(190, 190, 190);
  }
  .expansion-part {
    background-color: #e4e4e4;
    border-left: solid 10px #0091bd;
    border-radius: 3px 2px 2px 3px;
    border-top: 1px solid rgb(190, 190, 190);
  }
}

// .segment-tree:hover {
//     cursor: pointer;
// }
