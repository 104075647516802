.suggestion-item:hover {
    /*when hovering an item:*/
    background-color: #e9e9e9;
    cursor: pointer;
}

.text {
    margin-left: 1rem;
    font-size: large;
}

.suggestion-borders {
    border: 1px solid #eee;
    box-shadow: 0 2px 3px #ccc;
    box-sizing: border-box;
    
}

// .suggestion-box  {
//     max-height: 40vh;
//     overflow-y: scroll;
//     z-index: 100;
//     // position: fixed;
//     // width: 62vw;
//     padding: 10px;
// }

// // .suggestion-box {
// //     width:80vw;
// // }

// .suggestion-box > div {
//     background-color: #fefefe;
// }

// .text {
//     // margin:10px;
//     padding-bottom: 2px;
//     border-bottom: 1px solid black;
// }