.workflow {
}

.root {
  display: flex;
  margin-top: 2px;
  width: 100%;
  background-color: rgb(245,245,245);
}

.content {
  padding: 0px 0px 0px 2%;
  width: 100%;
  height: 85vh;
  overflow-y: scroll;
}

.menu-list {
  width: 100%;
  max-width: 240px;
  overflow-y:scroll;
  // overflow: hidden;
  // background-color: rgb(214,214,214);
}

.menu-list::-webkit-scrollbar {
  width: 10px;
}

.menu-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 10px rgba(0,0,0,0.3);
}

.menu-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 10px rgba(0,0,0,0.5);
}

.content::-webkit-scrollbar {
  width: 10px;
}

.content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 10px rgba(0,0,0,0.3);
}

.content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 10px rgba(0,0,0,0.5);
}

.list-item {
  color: black;
}

.sub-list-text {
  margin-left: 10px !important;
}

.name-field {
  display: flex;
  padding-top: 24px;
  padding-right: 24px;
  justify-content: space-evenly;
  align-items: center !important;
}

.workflow-name {
  flex: 1;
  padding-right: 10px !important;
}

.delete-icon {
  background-color: #db0808 !important;
  margin: 10px !important;
  color: white !important;
}

.save-button {
  margin-right: 'auto';
  margin-top: 24px;
  order: 2;
}

.heading {
  display:flex;
  justify-content: space-between;
  padding: 10px 10px 24px 0px;
  flex-direction: row-reverse;
}

.card {
  background-color: rgb(255, 255, 255) !important;
  margin-top: 24px;
  margin-right: 24px;
}

.card-actions-arrow {
  display: flex;
  width: 100%;
  padding-top: 24px;
  align-content: center;
  justify-content: center;
}

.card-icons {
  color: #003cbd !important;
  background-color: rgb(214,214,214) !important;
}

.action-expansion .MuiAccordionSummary-root[role="button"]:focus {
      box-shadow: 0 0 2pt 2.8pt rgb(142, 192, 209),
  }
