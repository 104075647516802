.customized-dialogs {
  .text-edit-position {
    background-color: #3f51b5;
    color: white;
  }
  .text-edit-position:hover {
    background-color: #2e3b84;
  }
  ins {
    background-color: rgba(0, 255, 0, 0.3);
  }

  del {
    background-color: rgba(252, 0, 0, 0.3);
  }
}

.diff-hide {
  display: none;
}

.diff-visible {
  padding: 0 10px 0 10px;
  margin: 0 0 0 10px;
  width: 100%;
  background-color: #f5f5f5;
}
