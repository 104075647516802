.status-input-modal {
  .input-field {
    margin: 1em;
    min-width: 70%;
    .MuiFormControl-root {
      min-width: 70%;
    }
  }
  .MuiDialogActions-root {
    padding: 32px 40px;
    justify-content: flex-start;
  }
}
