.register-container {
  padding: 170px 3em 0em 3em;
  p.MuiTypography-root.code-search-title.MuiTypography-body1 {
    text-align: right;
    margin-top: 30px;
    margin-bottom: none;
    font-size: xx-large;
    color: #464646;
  }

  .underline {
    width: 17em;
    background-color: #464646;
    border: 1px solid #464646;
    border-radius: 5px;
    padding: 0.3px;
  }
  .MuiAccordionDetails-root {
    padding-top: 1em !important;
  }
  .split-pane {
    width:90%;
    display:flex;
    .sidebar {
      flex: 1;
      font-size: 1.5rem;
      a {
        text-decoration: none !important;
      }
    }
    .register-content {
      flex: 6;
    }
  }
}

.register-title {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 0;
  font-size: xx-large;
  color: #464646;
  text-transform: uppercase;
}
