.workflow-selector {
    display: flex;
    flex-direction: column;
  .workflow {
    width: 345px;
    margin: 1em;
  }
  .workflow:hover {
    background-color:red;
  }
  .save-button {
    margin-left: auto;
  }
}
