.draft {
    .k-button {
        border: 1px solid black;
        border-radius: 4px !important;
        margin-left: 2px;
        background-color: white;
        color: black;
        padding: 6px 20px;
        font-size: 12px;
        cursor: pointer;
    }
    .edit-button {
        border-color: #0066a6;
        color: #0066a6;
        border-radius: 12%;
    }
    .delete-button {
        border-color: #f44336;
        color: red;
        margin-left: 5px;
        border-radius: 12%;
    }
    .notice-config {
        color: #f44336;
    }

    .modal-delete {
        border-color: #f44336;
        color: red;
    }
}