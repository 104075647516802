.adopted-rules {
  padding-top: 170px;

  .adopted-rules-title {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 0;
    font-size: xx-large;
    color: #464646;
    text-transform: uppercase;
  }

  .underline {
    width: 17em;
    background-color: #464646;
    border: 1.5px solid #464646;
    border-radius: 5px;
    padding: 0;
  }
}
.adopted-button-group {
  > span {
    margin-left: 0.5rem;
  }
}

.detail-panel {
  padding-bottom: 1rem;
  border-bottom: 1px solid #464646;
}

.detail-header {
  color: white;
  background-color: #3f51b5;
  border-radius: 5px;
  h4 {
    margin: 0.5rem;
  }
}
