.post-title {
  margin-bottom: 10px;
}

.create-button {
  margin: 10px 0px 10px 0px !important;
  display: flex;
  float: right;
  background-color: #004e9a !important;
  color: white !important;
  padding: 10px;
}
