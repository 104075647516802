.footer-main {
  display: flex;
  padding: 20px;
  background-color: #464646;
  .footer-left {
    padding: auto;
    padding-right: 2%;
    flex: 1;
    color: #fff;
  }
  .footer-right {
    padding: auto;
    padding-left: 2%;
    flex: 3;
    color: #fff;
  }
  a {
    color: white;
  }
}
