.form-label {
  font-size: 1.25em;
  font-weight: bold;
}

.filing-add {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;

  h5 {
    color: #3f51b5;
    margin: auto;
    font-weight: bolder;
  }

  .effect-checkbox {
    margin: 0.5em;
    color: #3f51b5;
  }

  .rules-label {
    margin-top: 1.5em;
  }

  .rules-group {
    margin-top: 0.5em;
    margin-bottom: 2em;
  }
  .form-group-checkbox {
    border: 1px solid #8a8a8a;

    // border: 1px solid rgb(204, 204, 204);
    // background-color: rgb(238, 238, 238);
    border-radius: 5px;
    padding: 0.5em;
    margin-bottom: 0.5em;
  }

  .selection-items > label {
    justify-content: center;
    margin-top: 10px;
  }
  .selection-items > div {
    margin-bottom: 10px;
  }
  .select {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .selection-items {
    margin: 5px;
    flex: 21;
  }
  .expiration {
    margin-top: 1em;
  }
  .text-input,
  .custom-select,
  .date-picker-input {
    @media screen and (max-width: 300px) {
      &[data-width="medium"] {
        min-width: auto;
      }
      min-width: auto;
      width: 100%;
    }
  }
  .date-picker-wrapper {
    display: flex;
    flex-direction: column;
    margin: 5px;
    padding-right: 5px;
  }
  .date-picker {
    padding: 10px;
    font-size: 13pt;
    border: 1px solid rgb(195, 195, 195);
    border-radius: 4px;
    outline-color: #3f50b5;
  }
  .label-date {
    font-size: 0.9em;
    margin-bottom: 0.2em;
  }
  .custom-select {
    min-width: 300px;
    text-overflow: ellipsis;
  }
  .drop-down-item {
    min-width: 300px !important;
    text-overflow: ellipsis;
  }
}
.filing-add,
.main-tab {
  .textfield {
    margin-top: 10px;
  }
  .superseded {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .save-button {
    float: right;
  }
  .field-wrapper {
    margin-bottom: 5px;
    padding: 5px;
    min-width: 200px;
  }
  .text-input {
    margin-top: 0px;
    &[data-width="medium"] {
      min-width: 400px;
    }
  }
  .custom-select {
    min-width: 300px;
    text-overflow: ellipsis;
  }
  .drop-down {
    min-width: 300px;
    text-overflow: ellipsis;
  }
  .custom-select > div {
    min-width: 300px;
    text-overflow: ellipsis;
  }
  #mui-component-select-rule {
    max-width: 300px;
  }
  [id*="name-text-field"] {
    max-width: 100%;
  }
  .date-picker > div {
    padding-right: 0px;
  }
  .date-picker-input {
    margin-top: 0px;
  }
  .flow-right {
    justify-content: flex-end;
  }
  .date-picker-wrapper {
    display: flex;
    flex-direction: column;
    margin: 5px;
    padding-right: 5px;
  }
  .date-picker {
    padding: 10px;
    font-size: 13pt;
    border: 1px solid rgb(195, 195, 195);
    border-radius: 4px;
    outline-color: #3f50b5;
  }
  .label-date {
    font-size: 0.9em;
    margin-bottom: 0.2em;
  }
  .drop-down-item {
    min-width: 300px !important;
    text-overflow: ellipsis;
  }
}
