.emergencyButton {
  color: #800000;
  width: 32px;
  height: 32px;
  margin-left: 15px;
  border-radius: 100%;
  .MuiIconButton-colorSecondary {
    color: #800000 !important;
  }
}
