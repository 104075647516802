.code-modal {
  div > div {
    max-width: 80vw;
  }
  .code-modal-title {
    .close-icon {
      position: absolute !important;
      right: 0px !important;
      top: 10px !important;
    }
  }

  .code-content > div:first-child > div:nth-child(1) {
    margin-top: 0 !important;
  }

  .code-content > p:first-child > div:nth-child(1) {
    margin-top: 0 !important;
  }

  .print-button-group {
    float: right;
    margin-right: 1.2em;
  }

  .button-group {
    float: right;
    margin-right: 1.2em;
  }
  .close-icon {
    align-content: center;
  }
  .modal-content {
    padding: 0.5rem;
    border-top: 1px solid grey;
    height: 80vh;
    overflow: auto;
    width: 100%;
  }

  .code-label {
    display: flex;
    font-size: 12pt;
    justify-content: center;
    font-family: "Times New Roman";
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .code-content {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 12pt;
    font-family: "Times New Roman";
  }
  .segment-note {
    margin-left: 1rem;
    font-size: 9pt;
    font-family: "Times New Roman", Times, serif;
  }

  .inline-note * {
    font-size: 9pt !important;
    font-family: "Times New Roman", Times, serif !important;
    display: inline-block;
  }

  .inline-note {
    margin-top: 0 !important;
    font-size: 9pt !important;
  }

  .inline-note-text {
    margin-bottom: 0px;
    font-size: 9pt !important;
  }

  .inline-note-text > * {
    display: inline;
    font-size: 9pt !important;
  }

  .inline-note-text > * > * {
    display: inline;
    font-size: 9pt !important;
  }

  .inline-note-text > * > * > * {
    display: inline !important;
    font-size: 9pt !important;
  }

  .segment-note * {
    font-size: 9pt !important;
    font-family: "Times New Roman", Times, serif !important;
    // display: inline;
  }

  .segment-note > * {
    display: inline !important;
    font-size: 9pt !important;
  }

  .segment-note > * > * {
    display: inline !important;
    font-size: 9pt !important;
  }

  img {
    width: 100% !important;
  }

  .Default {
    margin: 0;
  }

  .note-zone {
    text-decoration: none;
    color: black;
    display: inline;
  }

  .note-zone:hover {
    cursor: pointer;
    border-radius: 2px;
    border-bottom: 2px solid grey;
  }
}
