.dialog-content {
  display:flex;
  flex-direction: column;
  .text-fields {
    margin: 5px;
  }
  .select-boxes > div {
    div {
      text-overflow: ellipsis;
      width:100%;
    }
    padding: 5px;
    p {
      font-weight: bold;
    }
  }
  .drop-down {
    width: auto;
  }
  .chapter-form {
    border: 1px solid rgb(181, 181, 181);
    border-radius: 4px;
    > p {
      margin-bottom: .5rem;
    }
    > div {
      margin-bottom: .5rem;
      width:100% !important;
    }
  }
}