.item {
    margin: 1em auto;
}

.pager {
    .pagination {
        display:flex;
        flex-direction: row;
        list-style-type: none;

        .active {
            background-color:#3f51b5;
            color: white;
        }
        .page-item > button {
            background-color:white !important;
            border-radius: 0px;
            border: .1px solid grey;
        }

        .previous > button {
            background-color:white !important;
            border-radius: 0px;
            border: .1px solid grey;
        }

        .page-item > .page-link{
            padding:5px;
        }

        .next > button {
            background-color:white !important;
            border-radius: 0px;
            border: .1px solid grey;
        }

    }

    margin: auto;
    max-width: fit-content;
}