.k-button.k-danger,
.k-button.k-danger:active {
    // background-color: #f00;
    color: #f00;
    border-color: #f00;
}

colgroup {
    width: 100% !important;
}

.link {
    color: #00f;
    text-decoration: underline;
}