.state-register {
    min-height: auto;
    .edit-view {
        bottom: 20px !important;
    }
    .button-toolbar {
        border-top: .01rem solid lightgrey;
        border-right: .01rem solid lightgrey;
        border-left: .01rem solid lightgrey;
        border-radius: 4px 4px 0px 0px;
        padding: 10px 0px 10px 0px;
        display:flex;
        justify-content: space-between;
        .back-button {
            margin: 10px;
            background-color:#3f51b5;
            color:white;
            height: 30px;
        }
        //.register-preview-button {
        //    float: right;
        //}
    }

    //.register-options {
    //    margin-right: 1em;
    //}
    .register-checkbox {
        margin: 5px;
        padding-right: 1em;
    }

    .reg-gen-alert {
        margin: 8.5em;
        margin-right: -.1rem;
    }
    .button-group {
        float:right;
    }
}

.warning-banner {
    margin: 15px;
    font-size: 14pt;
}