.button-group {
    float:right;
    .k-button {
        border: 1px solid black;
        border-radius: 4px !important;
        margin-left: 2px;
        background-color: white !important;
        color: black;
        padding: 6px 20px;
        font-size: 12px;
        cursor: pointer;
    }
}

.k-grid-header .k-header {
    height: 20px;
    padding: 10px;
  }
.k-filter-row {
    height: 15px;
    th {
        padding: 5px !important;
    }

}

.download-links {
    color: blue;
}

.download-links a:hover {
    cursor: pointer;
    border-bottom: 1px solid blue;
}