.dialog-title-with-exit {
  .close-icon {
    position: absolute !important;
    right: 0px !important;
    top: 0px !important;
  }
  .close-icon:hover {
    //   color: /;
  }
}
