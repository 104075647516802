.float-right {
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 0.5rem;
  }
}

.button-margin {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.download-card {
  border: 1px solid rgb(119, 119, 119);
  border-radius: 4px;
  border-left: 5px solid #0066a6;
  margin-top: 1rem;
  .card-body {
    padding: 0.5rem;
  }
}
