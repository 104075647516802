.development-container {
  background-color: red;
  width: 100%;
  height: fit-content;
  position: fixed;
  bottom: 0;
  left: 0;
  .title-contianer {
    border-bottom: black 1px solid;
    margin: 1em;
    .min {
      position: absolute;
      right: 10px;
      top: 3px;
      width: 40px;
      height: 40px;
    }
  }
  .development-toolbar {
    padding: 1em;
    display: flex;
    background-color: transparent;
    width: 100%;
    // z-index: 10000000;
    align-items: stretch;
    justify-content: space-evenly;
    .date-container {
      display: flex;
    }
  }
}
.expand-development-container {
  width: 100%;
  height: fit-content;
  position: fixed;
  bottom: 0;
  .plus {
    position: absolute;
    right: 60px;
    top: -40px;
    width: 40px;
    height: 40px;
  }
}
