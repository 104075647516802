.text-edit-position {
    position: absolute;
    float: right;
}

.redline {
    text-decoration: line-through;
}

.underline-text {
    text-decoration: underline;
}