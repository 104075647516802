.filing-link:hover {
  cursor: pointer;
}

.center-table-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  > * {
    padding: 0.5rem;
  }
}

.k-toolbar {
  .button-group {
    width: 28%;
    justify-content: flex-start;
  }

  .filing-label {
    padding-left: 0.5rem;
  }

  // if there are nested divs make sure they are aligned
  // may cause effects on other tables
  div {
    align-items: center;
  }

  .right-button-group {
    > * {
      padding-right: 0.5rem;
    }
    width: 70%;
    justify-content: right;
    // background-color: green;
  }
}
