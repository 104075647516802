.navigation {
  .user-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  .MuiTabs-flexContainer {
  }
  .user-info {
    display: flex;
    min-width: 1em;
    .logout {
      transition: filter 100ms linear;
    }
    .logout:hover {
      filter: drop-shadow(0 0mm 1mm rgb(80, 80, 80));
    }
    .MuiAvatar-circle {
      transition: filter 100ms linear;
    }
    .MuiAvatar-circle:hover {
      filter: drop-shadow(0 0mm 1mm rgb(80, 80, 80));
    }
  }
  .tabs {
    color: black;
  }
  .MuiTabs-indicator {
    background-color: #004e9a;
  }

  .dropdown-button {
    color: rgba(0, 0, 0, 0.87);
  }
}
