.banner-bar {
    display:flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.banner-bar {
    position: relative;
    width: 100%;
    padding: .5rem .75em;
    // color: #fff;

    overflow: hidden;
}

.banner-bar:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-width: 0 16px 16px 0;
    border-style: solid;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.3), -1px 1px 1px rgba(0,0,0,0.2);
    -moz-box-shadow: 0 1px 1px rgba(0,0,0,0.3), -1px 1px 1px rgba(0,0,0,0.2);
    box-shadow: 0 1px 1px rgba(0,0,0,0.3), -1px 1px 1px rgba(0,0,0,0.2);
    /* Firefox 3.0 damage limitation */
    display: block; width: 0;
}

.banner-bar.rounded {
    -moz-border-radius: 5px 0 5px 5px;
    border-radius: 5px 0 5px 5px;
}

.banner-bar.rounded:before {
    border-width: 8px;
    border-color: #fff #fff transparent transparent;
    -moz-border-radius: 0 0 0 5px;
    border-radius: 0 0 0 5px;
}

.banner-bar-success {
    background: #669B41;
}
.banner-bar-success::before {
    border-color: #fff #fff #326820 #326820;
    background: #326820;
}

.banner-bar-info {
    background: #1CA6DF;
}
.banner-bar-info::before {
    border-color: #fff #fff #0066A6 #0066A6;
    background: #0066A6;
}

.banner-bar-warn {
    background: #DE9027;
}
.banner-bar-warn::before {
    border-color: #fff #fff #A96728 #A96728;
    background: #A96728;
}

.show-banner {
    visibility: visible;
}

.hide-banner {
    display: none;
}