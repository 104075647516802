.home {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  .image1 {
    width: 100vw;
    height: 110vh;
    margin-top: 7rem;
    max-height: 110vh;
    object-fit: cover;
    object-position: center;
    filter: aliasing(true) brightness(0.6) blur(0px);
    // transform: scale(1.2);
  }
  .image-footer {
    width: 100vw;
    max-height: 162px;
    object-fit: cover;
    object-position: center;
    filter: saturate(0) blur(0px);
    transform: scale(1.01);
    z-index: 100;
  }
  .image-wrapper {
    min-height: 100px;
    overflow: hidden;
    position: relative;
  }
  .body-wrapper {
    // border: 2px dashed rgba(28,166,223,.8);
    // border-radius: 10px;
    // width: 100vw;

    justify-content: space-evenly;
    margin-bottom: 2em;
    display: flex;
    flex: 0;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .main-title {
    font-size: 5.2vw;
    object-fit: scale-down;
    color: white;
    font-family: "Montserrat", Cambria, "Hoefler Text", "Liberation Serif",
      "Times New Roman";
  }
  .container {
    position: relative;
  }
  .center-image {
    position: absolute;
    max-width: 12vw;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .title-center-search {
    color: #0f1113;
    position: absolute;
    font-family: "Montserrat", "Open Sans", sans-serif;
    word-wrap: break-word;
    text-align: center;
    top: 45%;
    left: 50%;
    right: 0%;
    transform: translate(-50%, -50%);
  }
  // .title-underline {
  //   box-shadow: inset 0 -0.082em white, inset 0 -0.1em black,
  //     inset 0 -0.17em white, inset 0 -0.175em black;
  //   margin-bottom: 20px;
  // }

  .underline {
    width: 15em;
    background-color: #464646;
    border: 1px solid #464646;
    border-radius: 5px;
    // padding: 0.3px;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    // This fixes the styling used by tiny MCE and should be put in a tinyMCE configuration file
    margin: 0px;
    padding-left: 10px;
    padding-right: 10px;
    // padding-bottom: 10px;
  }
  .title {
    display: flex;
    justify-content: center;
    font-size: 36px;
    font-weight: 300;
    padding-top: 15px;
    text-transform: uppercase;
    margin-right: 5%;
    margin-left: 5%;
  }
  .title > p {
    padding: 0px;
  }
  .title-body {
    padding: 0px, 0px, 0px, 20px;
    font-size: 22pt;
    font-weight: 300;
    text-transform: uppercase;
  }
  .center-text {
    text-align: center;
  }

  .body1 {
    font-family: "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1.1em;
    line-height: 1.43;
  }
  .body2 * {
    font-family: "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1.1em;
    line-height: 1.43;
  }
  .body-pane {
    margin-right: 10%;
    margin-left: 10%;
  }
  .body-split-pane {
    padding: 30px 15% 30px 15%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .body-split-pane-color {
    background-color: #f6f6f6;
  }

  // not a fan of fixed sizes. But this ensure the left and right containers wrap.
  .left {
    padding-right: 10px;
    flex: 1;
    max-width: 500px;
    align-content: center;
  }
  .right {
    padding-right: 10px;
    max-width: 500px;
    flex: 1;
    align-content: center;
  }
  .image-left {
    // object-fit: contain;
    width: 500px;
    // overflow: none;
    flex-grow: 0;
    object-fit: contain;
  }
  .image-right {
    width: 500px;
    // height: auto;
    // flex-wrap: wrap;
    object-fit: contain;
    flex-grow: 0;
  }
  .footer {
    display: flex;
    background-color: #000;
    padding: 25px;
    margin-top: auto;
  }
  // .menu {
  //   display: flex;
  //   position: fixed;
  //   justify-content: flex-start;
  //   background-color: rgba(214, 214, 214, 0.8);
  // }
  .menu-login {
    margin-left: auto;
  }
  .menu-title {
    text-transform: uppercase;
  }
  .text-wrapper {
    position: absolute;
    left: 50%;
    right: 0%;
    transform: translate(-50%, 10%);
  }
}
.skiplink-text {
  position: absolute;
  left: -999px;
  width: 1px;
  height: 1px;
  top: auto;
  font-size: medium;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;

  &:focus {
    color: black;
    background-color: white;
    display: inline-block;
    z-index: 1200;
    position: absolute;
    height: auto;
    width: auto;
    top: 0px;
    left: 5px;
    margin: auto;
    outline: none;
    box-shadow: 0 0 2.5pt 2.5pt #51a9eb;
  }
}
