.post-container {
  margin: 1em;
  border: 1px solid rgb(88, 88, 88);
  padding: 0.5em 2em;
  border-radius: 3px;
  margin-bottom: 3px;
  h1 {
    margin-top: auto;
  }
  .post-body-footer {
    position: flex;
    justify-content: space-between;
  }
}
.actions {
  display: flex !important;
}
