.logo-wrapper {
  max-width: 25rem;
  overflow: hidden;
}
.menu {
  .menu-item {
    // flex-wrap: nowrap;
  }

  .body {
    width: 95vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
  }

  .float-right {
    float: right;
  }

  .float-left {
    float: left;
  }

  .menu-button > span > a {
    text-decoration: none;
    color: rgb(0, 0, 0);
  }

  .menu-button {
    // gives consistent gaps between kendo and material buttons/dropdowns
    svg {
      margin-right: 0.5rem;
    }
  }

  .MuiButton-root {
    // sets font weight to be the same as kendo dropdown
    font-weight: 400 !important;
  }
}

.menu {
  // removing this style to prevent scrollbars in the header
  // overflow: auto !important;
  flex-wrap: wrap;
}

.mobile-menu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8rem;
  max-height: 9em;
}

.menu-icon {
  // position: absolute !important;
  z-index: 100;
}

.mobile-logo {
  height: 3rem;
}

.k-dropdown {
  background-color: transparent !important;
}

.menu-dropdown {
  color: rgba(0, 0, 0, 0.87);
  > button {
    font-weight: 400 !important;
    padding: 0.5rem;
  }
}

// Commenting out this code because it was causing issues site wide with the styles
// .k-dropdown .k-dropdown-wrap,
// .k-dropdown.k-state-border-down .k-dropdown-wrap.k-state-border-down {
//   padding: 5px !important;
//   border: 1px solid #959694 !important;
//   border-radius: 20px 20px 20px 20px !important;
// }

// .k-dropdown .k-dropdown-wrap .k-select,
// .k-ie .k-dropdown .k-dropdown-wrap .k-select,
// .k-ff .k-dropdown .k-dropdown-wrap .k-select {
//   background-color: #f6f7ef !important;
//   padding: 8px !important;
//   border-left: solid #959694 thin !important;
//   border-right: solid #959694 thin !important;
//   border-radius: 0 20px 20px 20px !important;
// }
