.titleLabel {
  background-color: #800000 !important;
  color: rgb(248, 248, 248) !important;
  // border-left: 5px solid red !important;
}

.titleName {
  border-left: 5px, #800000 !important;
}

.chapterLabel {
  background-color: rgb(255, 255, 255) !important;
  color: rgb(77, 10, 10) !important;
  border-left: 10px solid #800000 !important;
}

.chapterName {
  border-left: 5px, solid #800000 !important;
}

.draft-content {
  padding-left: 15px;
  border-left: 15px solid#800000 !important;
  border-top: 1px solid #800000 !important;
  height: 150px;
}
.draft-content > div {
  font-size: large;
  padding-top: 8px;
  padding-bottom: 8px;
}
