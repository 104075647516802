.alert-dialog {
    .message {
        display: flex;
        align-items: center !important;
        text-align: center;

        .alert-icon {
            color: rgb(255, 60, 0);
        }

        >* {
            margin: 5px;
        }
    }
}