/* Detail Styles */
.reg-results-info {
    display:flex;
    justify-content: center;
}

.results-register {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
    margin: auto;
    margin-left: 0em;
    margin-right: 0em;
}

.results-register .result-register {
    width: 100%;
    height: 50rem;
}
