.rule-modal {
}
.rule-dialog > div > div {
    width: 50vw !important;
    max-width: 100vw !important;
}
.contact-selection {
    display:flex;
    // flex-direction: row;
    div:first-child {
        width: 50%;
        padding-right: 10px;
        div {
            width: 100%
        }
    }
    div:nth-child(2) {
        width: 50%;
        div {
            width: 100%
        }
    }
}

.contact-chip {
    width:45% !important;
    height: 1.2rem !important;
    margin-right: 5px;
    margin-bottom: 5px;
}
.menu-item {
    display: flex;
    flex-direction: row;
    margin: 0px;
    float: left;
    align-items: center;
    justify-items: center;
}

.error-text {
    color: #f44336 !important;
}

.contact-menu-item {
    display:flex;
    justify-content: space-between !important;
    width: 100%;
}

.select-menu-icon {
    background-color:#3f51b5 !important;
    color: #ffffff !important;
    border-radius: 4px;
}

.select-menu-icon::hover {
    cursor: pointer;
}

