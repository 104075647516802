$border-radius: 2.25rem;
$dark-theme: false;
$primary: #004e99;
$secondary: #6c757d;
$info: #0dcaf0;
$success: #198754;
$warning: #ffc107;
$error: #dc3545;
$body-text: #212529;
$body-bg: #ffffff;
$headings-text: #212529;
$subtle-text: #6c757d;
$disabled-text: #8f8f8f;
$component-text: #212529;
$component-bg: #ffffff;
$base-text: #212529;
$base-bg: #f8f9fa;
$hovered-text: #16181b;
$hovered-bg: #e9ecef;
$selected-text: #ffffff;
$selected-bg: #004e99;
$button-text: #212529;
$button-bg: #e4e7eb;
$link-text: #004e99;
$link-hover-text: #0a58ca;
$series-a: #0275d8;
$series-b: #5bc0de;
$series-c: #5cb85c;
$series-d: #f0ad4e;
$series-e: #e67d4a;
$series-f: #d9534f;

@import "~@progress/kendo-theme-bootstrap/scss/panelbar/_index.scss";