.current-rules-preview {
  > div > div {
    min-width: 75%;
  }
  .section-label {
    display: inline-block;
  }
  .segment-paragraph {
    font-family: "Times New Roman", Times, serif;
    font-size: 12pt;
  }
  .segment-paragraph div:first-child {
    margin-top: 0 !important;
  }

  img {
    width: 100% !important;
  }

  .segment-paragraph p:first-child {
    margin-top: 0 !important;
  }
  // .inline-note-text {
  //     display:inline;
  //     font-size: 11px !important;
  // }

  .note {
    // display:flex;
    font-size: 9pt !important;
    // margin-bottom: 12px;
    // margin-top: 12px;
  }
  .segment-title {
    display: flex;
    font-family: "Times New Roman", Times, serif;
    font-size: 12pt;
    font-weight: bold;
    justify-content: center;
  }
  .segment-header {
    display: flex;
    font-family: "Times New Roman", Times, serif;
    font-size: 12pt;
    font-weight: bold;
    justify-content: center;
  }
  .segment-section-header {
    display: flex;
    font-family: "Times New Roman", Times, serif;
    font-size: 12pt;
    font-weight: bold;
  }
  .segment-item {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .preview-segment-note {
    > * {
      display: inline-block !important;
    }
    display: block !important;
    font-size: 9pt !important;
    // float: left;
  }

  .center-note {
    display: flex;
    justify-content: center;
  }

  .Default {
    margin: 0;
  }

  .segment-paragraph p {
    // margin: 0;
  }
}
