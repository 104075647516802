.user-indicator {
  .user-info {
    padding: 3px;
    display: flex;
    align-items: center;
  }
  .logout {
    margin-left: 5px;
  }
}
