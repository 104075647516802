.action-icon {
    width:84px !important;
    height:81px !important;
  .primary {
    color: white;
    background-color: #2979ff;
  }
  .primary:hover {
    background-color: #1c54b2;
  }
  .warning {
    color: white;
    background-color: #ff9100;
  }
  .warning:hover {
    background-color: #b26500;
  }
  .danger {
    color: white;
    background-color: #f44336;
  }
  .danger:hover {
    background-color: #aa2e25;
  }
  .success {
    color: white;
    background-color: #52b202;
}
.success:hover {
    background-color: #618833;
  }
}
