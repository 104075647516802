.filing-preview {
    > div > div  {
        min-width: 80%;
        min-height: 95%;
    }
    .section-label {
        display:inline-block;
    }
    .container {
        .paragraph {
            max-height: 50% !important;
        }
    }
    .preview-details {
        min-height: 100%;
        padding:10px;
    }
}