.status
{
    width: 220px;
    .MuiCardContent-root {
        text-align: center;
    }    
    .actions{
        display: flex;
        flex-direction: row;
        .action{
            margin: 1rem;
        }
        .action-edit-wrapper{
            margin: 1rem;
            .action{
                margin: 0rem;
            }
        }
    }
}