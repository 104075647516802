.action-edit-wrapper
{
    display: flex;
    >svg{
        transition: color ease-out 250ms;
        font-size:12pt;
        text-align: end;    
    }
    >svg:hover + .action>button{
        background-color: #932020;   
        cursor: pointer;
    }
    >svg:hover{
        color: red;   
        cursor: pointer;
    }

}