.filing-preview {
    > div > div  {
        min-width: 80%;
        min-height: 95%;
    }
    .section-label {
        display:inline-block;
    }
    .attachments-container {
        margin-top: 1rem;
    }

    .label {
        background-color: #004e9a;
        border-radius: 4px;
        color:white;
        padding: 0.25rem;
        // font-size: 1rem;
    }
}