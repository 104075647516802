$panel-bar-bg: red;
@import "OK_Theme_PanelBar.scss";

.result {
  // width: 250px;
  width: 80%;
  // padding: 16px;
  // border: 1px solid #eee;
  // box-shadow: 0 2px 3px #ccc;
  // margin: 10px;
  // margin-bottom: 3px;
  // padding-bottom: 3px;
  // box-sizing: border-box;
  cursor: pointer;
}

.result:hover,
.result:active {
  background-color: #c0ddf5;
}

.title-style {
  vertical-align: middle;
}

.result-details {
  display: flex;
}

.results-header {
  text-align: center;
  justify-items: start;
  padding-right: 10px;
  margin: 0.5em;
  display: inline-flex;
  margin: 0 1rem;
  max-width: 100%;
}

.card-img-top {
  width: auto;
  min-width: 6em;
  max-width: 10em;
  height: auto;
  max-height: 12em;
  vertical-align: middle;
}

.result-header-item {
  font-size: medium;
  color: #3f51b5;
}

.result-header-value {
  font-style: italic;
  color: gray;
}

.header-values {
  display: inline-flex;
  margin: 0 1rem;
  max-width: 100%;
}

.center {
  text-align: center;
}

.name-spacing {
  width: 10%;
  text-align: left;
}

.title-spacing {
  width: 70%;
  text-align: left;
}

.code-spacing {
  width: 20%;
  text-align: left;
}

.agency-display {
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  background-color: #c74a27;
  color: white;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  height: 100%;
  // width: 25%;
}

.grid-layout-container {
  overflow-x: auto;
}
.editor-holder {
  /* other base styles go here */
  // display: flex;
  justify-content: center;
  align-items: center;
  height: 15em;
  padding-bottom: 2%;
  padding-top: 2%;
  padding-left: 1%;
  padding-right: 1%;
}

// Segment Display SCSS'
.segment-display {
  width: 100%;
  height: 30px;
  .segment-children {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 30px;
  }

  h4 {
    color: #000;
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 1.3em;
    margin-left: 15px;
  }

  a {
    text-decoration: none;
    margin-left: 5px;
    margin-bottom: inherit;
  }

  p {
    font-size: small;
  }
}

.level-0 {
  .MuiPaper-root.owning-body.MuiPaper-elevation1.MuiPaper-rounded {
    border-left: 15px solid #117bc0;
  }
}
.level-1 {
  padding: 0px;

  .MuiPaper-root.owning-body.MuiPaper-elevation1.MuiPaper-rounded {
    border-left: 15px solid #679d42;
  }
  div#panel1a-header:hover {
    border-left: 8px solid #679d42;
    margin-left: -8px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}

.level-2 {
  .MuiPaper-root.owning-body.MuiPaper-elevation1.MuiPaper-rounded {
    border-left: 15px solid #de9027;
  }
  div#panel1a-header:hover {
    border-left: 8px solid #de9027;
    margin-left: -8px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}
.level-3 {
  .MuiPaper-root.owning-body.MuiPaper-elevation1.MuiPaper-rounded {
    border-left: 15px solid #aa6728;
  }
  div#panel1a-header:hover {
    border-left: 8px solid #aa6728;
    margin-left: -8px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}
.level-4 {
  .MuiPaper-root.owning-body.MuiPaper-elevation1.MuiPaper-rounded {
    border-left: 15px solid #1ba6df;
  }
  div#panel1a-header:hover {
    border-left: 8px solid #1ba6df;
    margin-left: -8px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}
.level-5 {
  .MuiPaper-root.owning-body.MuiPaper-elevation1.MuiPaper-rounded {
    border-left: 15px solid #316821;
    margin-bottom: 25px;
    background-color: #316821;
  }
  div#panel1a-header:hover {
    border-left: 8px solid #316821;
    margin-left: -8px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #316821;
  }
}

.make-scrollable {
  max-height: 16rem !important;
  overflow-y: scroll;
  overflow-x: auto;
  margin: 5px;
}
