.segment-dialog {
  display: flex;
  justify-content: center;
  .close-icon {
    margin: 10px;
    position: absolute !important;
    right: 0px !important;
    top: 0px !important;
  }
}
