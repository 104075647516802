.comment {
}

.history-contents {
  display: flex;
}
.history-contents > div {
  width: 50%;
  padding: 5px;
}
