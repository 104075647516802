.filter-label {
    display: inline-flex;
    text-align:center;
    list-style-type: none;
    padding-left:1em;
    // margin-left: 20%;
    // margin-right: 20%;
}

.listitem {
    display:flex;
    flex-wrap: wrap;
}

.MuiCollapse-wrapperInner {
    ::-webkit-scrollbar {
        width: 10px;
        border-radius: 10px;
      }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
    }

}

@media (max-width: 960px) and (min-width: 501px) {
    .display  li { width: 50%; } /* Show 2 logos per row on medium devices (tablets, phones in landscape) */
  }
  
  @media (max-width: 500px) {
    .display  li { width: 100%; } /* On small screens, show one logo per row */
  }