.button-group {
    float:right;
}

.k-grid-header .k-header {
    height: 20px;
    padding: 10px;
  }
.k-filter-row {
    height: 1px;
        padding: 5px !important;
    }

