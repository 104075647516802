$panel-bar-bg: red;
@import "OK_Theme_PanelBar.scss";

.hidden {
  // display:
  // width: 0px;
}

.text-display {
  max-height: 820px;
  overflow-y: scroll;
  overflow-x: auto;
  margin: 5px;
}

.code-display {
  img {
    max-width: 100%;
  }
  p {
    max-width: 100%;
  }
}

.appendix-label {
  display: flex;
  justify-content: center;
}

.segment-note {
  margin-bottom: 12px;
}
.note-zone {
  text-decoration: none;
  color: black;
}
.inline-note-text > * {
  display: inline !important;
}
.note-zone:hover {
  cursor: pointer;
  border-radius: 2px;
  border-bottom: 2px solid grey;
}
