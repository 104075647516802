.file-upload-dialog {
}
.image-edit-wrapper {
  position: relative;
  .image-edit-position {
    background-color: #3f51b5;
    color: white;
    position: absolute;
    -webkit-transform: translateX(105%);
    transform: translateX(105%);
    -webkit-transform: translateY(-115%);
    transform: translateY(-115%);
  }
  .image-edit-position:hover {
    background-color: #2e3b84;
  }
}
