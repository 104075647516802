
.add-segment-modal {
    > div > div {
        width: 60%;
        height: 40%;

    }
    .segment-name {
        width: 100%;
    }
    .segment-number {
        width: 100%;

    }
}

.saved-button {
    color: blue;
    margin-right: 5px;
}

.cancel-button {
    color: red;
    margin-right: 5px;
}


.save-container {
    margin-right: 10px;
    margin-bottom: 5px;
    overflow-y: hidden;
}
