.banner {
  // height: 1rem;
  background-color: #034d9a;
  border-radius: 0.3rem;
  color: white;
  padding: 1rem;
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.history-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  > * {
    padding: 0.5rem;
  }
}

.history-modal {
  div > div {
    width: 100%;
    max-width: 80vw;
  }
}

.history-text {
  width: 100%;
  // clears margins that were inserted during data conversion
  > div {
    margin: 0px !important;
  }
  img {
    width: 100%;
  }
}
