/* Detail Styles */
.results-info {
    display:flex;
    justify-content: center;
}

.results {
    // display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
    margin: auto;
    margin-left: 0em;
    margin-right: 0em;
}

// .results .result {
//     width: 80%;
//     max-height: 18rem;
// }

// .results .result {
//     overflow-y: scroll;
// }