.button-app-bar {
  position: sticky;
  display: flex;


  .toolbar {
    background-color: #0066A6;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-top: 2em;
    padding: inherit;
  }

  .shrunk-h2 {
    font-weight: bold;
  }

  .button-app-bar {
    background-color: white;
    font-weight: bolder;
  }
  .button-app-bar:hover {
    background-color: #ebeae8;
  }
  .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
    justify-content: space-between;
  }
}
