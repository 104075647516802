.card {

}

.card-actions-arrow {
  display: flex;
  width: 100%;
  align-content: center;
  justify-content: center;
}

.card-actions-new {
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  width: 100%;
  align-content: center;
  justify-content: center;
}

.card-actions {
  justify-content: flex-end;
}

// !important used to override material default colors
.add-new-icon {
  background-color: #004E9A !important;
  color: white !important;
}

.card-icon {
  color: #003cbd !important;
  background-color: rgb(214,214,214) !important;
  float: right;
}

.card-icon-warn {
  color: #db0808 !important;
  background-color: rgb(214,214,214) !important;
  float: right;
}