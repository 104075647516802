.proposed-rules {
  padding: 170px 3rem 0rem 3rem;
  .proposed-rules-title {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 0;
    font-size: xx-large;
    color: #464646;
    text-transform: uppercase;
  }

  .underline {
    width: 17em;
    background-color: #464646;
    border: 1.5px solid #464646;
    border-radius: 5px;
    padding: 0;
  }
  .proposed-rule-item {
    margin-left: 3rem;
    margin-right: 3rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.2rem;
    // color: white;
    border-left: 0.5rem solid #0066a6;
    border-top: 1px solid #464646;
    border-right: 1px solid #464646;
    border-bottom: 1px solid #464646;
    button {
      padding: 0.2rem 0.4rem 0.2rem 0.4rem;
      margin-bottom: 0.5rem;
    }
  }
}
