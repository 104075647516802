.form-label {
  font-size: 1.25rem;
  font-weight: bold;
}

.filing-add,
.action-tab {
  .action-button {
    min-width: 48%;
    margin-right: 1%;
    margin-left: 1%;
    margin-top: 1rem;
    background-color:#3f51b5;
    .action-description {
      display:flex;
      flex-wrap: wrap;
      align-items: flex-start;
      flex-direction: column;
      div {
        font-size: .7em;
      }
    }
  }
}

.action-tab > div > p {
  margin-left: .6em !important;
  text-decoration: underline solid rgba(0, 0, 0, 0.18);
  text-underline-offset: 0.2rem;
  font-weight: inherit;
}

.reject-reason-wrapper {
  margin-left: .6em !important;
}

.reject-reason-reason {
  width: 100%;
}

.reject-reason-title {
  text-decoration: underline solid rgba(0, 0, 0, 0.18);
  text-underline-offset: 0.2rem;
  font-weight: inherit;
}

.reject-reason-button {
  font-weight: bold !important;
  color: white !important;
}

.reject-reason-button:hover {
  background-color: #303f9f !important;
}