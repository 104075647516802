.display-pane-left {
  margin: 5px 2.5px 0px 0px;
  width: 50%;
  padding: 10px;
  overflow-y:scroll;
}

.mywork-news {
  margin: 1em;
}

#state-news {
  max-height: 20vh;
  overflow-y: auto;
}
