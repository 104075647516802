.split-display {
  display: flex;
}

.display-pane-left {
  margin: 5px 2.5px 0px 0px;
  width: 50%;
  padding: 10px;
}

.display-pane-right {
  margin: 5px 0px 0px 2.5px;
  width: 50%;
  padding: 10px;
}

.tab-menu {
  padding: 0.2rem !important;
  font-size: 0.7rem !important;
  background-color: #ffffff !important;
}

.dashboard {
  display: flex;
  flex-direction: row;
}
.dashboard > div {
  margin: 2px;
  flex: 1;
}

.accordion-summary {
  min-height: 2rem !important;
  > div {
    margin: 0px !important;
  }
  .accordion-label {
    width:100%;
    display:flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between !important;
    color:white;
  }
  background-color:#0066a6 !important;
  border-radius: 5px !important;
  margin-bottom: 2px !important;
}
.accordion-details {
 padding-top: 1rem !important;
}
